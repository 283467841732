<template>
  <v-dialog
    v-model="dialogInput"
    :width="widthInput"
    persistent
  >
    <v-card>
      <v-card-actions>
        <v-row align="center" justify="end">
        <v-btn
          color="error"
          dark
          fab
          small
          @click="dialogInput = false"
          class="mt-2"
        >
        <v-icon dark>
          mdi-close
        </v-icon>
        </v-btn>
      </v-row>
      </v-card-actions>
      <v-card-title class="headline primary--text justify-center text-center mb-5" style="word-break: break-word;">
        {{tituloInput}}
      </v-card-title>
      <v-card-text>
        <slot></slot>
      </v-card-text>
    </v-card>
    <v-overlay :value="loadingInput" :z-index="9999">
      <v-progress-circular
        indeterminate
        :size="100"
        color="primary"
        :width="10"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean
    },
    titulo: {
      type: String
    },
    width: {
      type: Number
    },
    loading: {
      type: Boolean
    }
  },
  methods: {
    clicBoton (accion) {
      this.$emit('clic-boton', accion)
    }
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialog
      },
      set: function (newValue) {
        this.$emit('update:dialog', newValue)
      }
    },
    tituloInput: {
      get: function () {
        return this.titulo
      },
      set: function (newValue) {
        this.$emit('update:titulo', newValue)
      }
    },
    widthInput: {
      get: function () {
        return this.width
      },
      set: function (newValue) {
        this.$emit('update:width', newValue)
      }
    },
    loadingInput: {
      get: function () {
        return this.loading
      },
      set: function (newValue) {
        this.$emit('update:loading', newValue)
      }
    }
  }
}
</script>
