<template>
  <DialogVentana :dialog.sync="dialogInput" :loading.sync="loading" :width="500">
    <v-card style="box-shadow: none;">
      <v-card-text>
        <p class="display-1 primary--text font-weight-regular mt-2"> {{modoInput}} {{titulo}}</p>
        <v-form ref="form" v-model="valid">
          <v-text-field
            id="nombreInput"
            label="Nombre"
            v-model="nombre"
            :rules="[requerido]"
            counter
            maxlength="45"
            class="mb-4"
            @change="existeExamen"
            @keydown="checkKeyDownAlphaNumeric($event)"
          ></v-text-field>
          <SelectCrud
            :items="$store.state.medidas"
            store="cambiarMedidas"
            metodo="cargarListaMedidas"
            :model.sync="medida"
            api_carga="medidas/listado"
            :dialog.sync="dialogMedida"
            label="Medida"
            :modo.sync="modoMedida"
            nnuevo="NUEVA">
            <Medida :modo.sync="modoMedida" :dialogME.sync="dialogMedida" :nmedida.sync="medida" origen="select"/>
          </SelectCrud>
          <v-text-field
            label="Referencia"
            v-model="referencia"
            :rules="[requerido]"
            counter
            maxlength="100"
            class="mb-4"
          ></v-text-field>
          <SelectCrud
            :items="$store.state.metodos"
            store="cambiarMetodos"
            metodo="cargarListaMetodos"
            :model.sync="metodo"
            api_carga="metodos/listado"
            :dialog.sync="dialogMetodo"
            label="Método"
            :modo.sync="modoMetodo"
            nnuevo="NUEVO">
            <Metodo :modo.sync="modoMetodo" :dialogMET.sync="dialogMetodo" :nmetodo.sync="metodo" origen="select"/>
          </SelectCrud>
          <SelectCrud
            :items="$store.state.reactivos"
            store="cambiarReactivos"
            metodo="cargarListaReactivos"
            :model.sync="reactivo"
            api_carga="reactivos/listado"
            :dialog.sync="dialogReactivo"
            label="Reactivo"
            :modo.sync="modoReactivo"
            nnuevo="NUEVO">
            <Reactivo :modo.sync="modoReactivo" :dialogR.sync="dialogReactivo" :nreactivo.sync="reactivo" origen="select"/>
          </SelectCrud>
          <SelectCrud
            :items="$store.state.tiposexamen"
            store="cambiarTiposExamen"
            metodo="cargarListaTiposExamen"
            :model.sync="tipoexamen"
            api_carga="examentipos/listado"
            :dialog.sync="dialogTipoExamen"
            label="Tipo Examen"
            :modo.sync="modoTipoExamen"
            nnuevo="NUEVO">
            <TipoExamen :modo.sync="modoTipoExamen" :dialogTE.sync="dialogTipoExamen" :ntipoexamen.sync="tipoexamen" origen="select"/>
          </SelectCrud>
          <v-autocomplete
            label="Tipo en Informe"
            :items="['Normal', 'Subtítulo']"
            v-model="tipo_informe"
            clearable
            class="pr-3 mb-3"
            :rules="[requerido]"
          ></v-autocomplete>
          <v-autocomplete
            label="Aparece en"
            :items="['Todo', 'Plantillas']"
            v-model="aparece_en"
            clearable
            class="pr-3 mb-3"
            :rules="[requerido]"
          ></v-autocomplete>
          <v-textarea
            v-model="notas"
            auto-grow
            filled
            label="Notas"
            rows="2"
            required
            class="mt-6 mb-3"
            :rules="[requerido]"
            counter
            maxlength="200"
          ></v-textarea>
          <v-row justify="center">
            <v-btn
              color="primary"
              @click="guardar"
              class="mb-5"
              :disabled="!valid"
            >
            GUARDAR
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </DialogVentana>
</template>

<script>
import SelectCrud from '@/components/selectcrud.vue'
import Medida from '@/views/medida.vue'
import Metodo from '@/views/metodo.vue'
import Reactivo from '@/views/reactivo.vue'
import TipoExamen from '@/views/tipoexamen.vue'
import ApiCon from '@/logic/apicon.js'
import Globales from '@/mixins/globales.vue'
import DialogVentana from '@/components/dialogventana.vue'

export default {
  data: () => ({
    titulo: 'Exámen',
    nombre: '',
    referencia: '',
    notas: '',
    medida: '',
    metodo: '',
    reactivo: '',
    tipoexamen: '',
    loading: false,
    valid: false,
    requerido: v => !!v || 'Este campo es obligatorio',
    idexamen: 0,
    dialogMedida: false,
    modoMedida: 'NUEVA',
    dialogMetodo: false,
    modoMetodo: 'NUEVO',
    dialogReactivo: false,
    modoReactivo: 'NUEVO',
    dialogTipoExamen: false,
    modoTipoExamen: 'NUEVO',
    tipo_informe: 'Normal',
    aparece_en: 'Todo'
  }),
  mixins: [
    Globales
  ],
  components: {
    SelectCrud,
    Medida,
    Metodo,
    Reactivo,
    TipoExamen,
    DialogVentana
  },
  props: ['modo', 'dialogE', 'nexamen', 'origen'],
  methods: {
    reset () {
      this.nombre = ''
      this.referencia = ''
      this.notas = ''
      this.medida = ''
      this.metodo = ''
      this.reactivo = ''
      this.tipoexamen = ''
      this.medidaback = 'white'
      this.metodoback = 'white'
      this.reactivoback = 'white'
      this.tipoback = 'white'
      this.idexamen = 0
    },
    async existeExamen () {
      // this.nombre = this.removeSpecialCharacters(this.nombre)
      const response = await ApiCon.get2('examenes/existe', this.nombre, this.$session.get('usuario'))
      if (response.status === 200) {
        if (response.data === true) {
          this.nombre = ''
          alert('El examen ya se encuentra registrado.')
        }
      } else { alert('error existeExamen') }
    },
    async cargarExamen () {
      this.loading = true
      const response = await ApiCon.get2('examenes/uno', this.nexamen, this.$session.get('usuario'))
      if (response.status === 200) {
        this.nombre = response.data.nombre
        this.referencia = response.data.referencia
        this.notas = response.data.notas
        this.medida = response.data.medida
        this.metodo = response.data.metodo
        this.reactivo = response.data.reactivo
        this.tipoexamen = response.data.examen_tipo
        this.idexamen = response.data.idexamen
        this.tipo_informe = response.data.tipo_informe
        this.aparece_en = response.data.aparece_en
      } else { alert('Error en cargarExamen...') }
      this.loading = false
    },
    postMod () {
      if (this.origen === 'select') {
        this.cargarTabla('examenes/listaexamenesnoplantilla', 'cambiarExamenes', 'cargarListaExamenes')
        this.nexamenInput = this.nombre
      } else if (this.origen === 'selectfiltro') {
        this.cargarTablaFiltro('examenes/listaexamenesnoplantillafiltro', 'cambiarExamenes', 'cargarListaExamenes', this.nombre)
        this.nexamenInput = this.nombre
      } else if (this.origen === 'tabla') {
        this.cargarTabla('examenes/todo', 'cambiarTabla', 'cargarTablaExamenes')
      }
      this.reset()
      this.$refs.form.reset()
      this.dialogInput = false
    },
    async guardar () {
      if (this.valid === true) {
        this.loading = true
        var ins = {}
        if (this.modo === 'NUEVO') {
          ins = {
            nombre: this.nombre,
            medida: this.medida,
            referencia: this.referencia,
            metodo: this.metodo,
            reactivo: this.reactivo,
            examen_tipo: this.tipoexamen,
            tipo_informe: this.tipo_informe,
            aparece_en: this.aparece_en,
            notas: this.notas,
            usuario: this.$session.get('usuario')
          }
          const response = await ApiCon.post('examenes/insertar', ins)
          if (response.status === 200) {
            alert('El examen ha sido registrado.')
            this.postMod()
          } else { alert('Ha ocurrido un error con el guardado de datos') }
        } else if (this.modo === 'EDITAR') {
          ins = {
            idexamen: this.idexamen,
            nombre: this.nombre,
            medida: this.medida,
            referencia: this.referencia,
            metodo: this.metodo,
            reactivo: this.reactivo,
            examen_tipo: this.tipoexamen,
            tipo_informe: this.tipo_informe,
            aparece_en: this.aparece_en,
            notas: this.notas,
            usuario: this.$session.get('usuario')
          }
          const response = await ApiCon.post('examenes/update', ins)
          if (response.status === 200) {
            alert('El examen ha sido actualizado.')
            this.postMod()
          } else { alert('Ha ocurrido un error con la edición de datos') }
        }
        this.loading = false
      } else {
        alert('Corrobore si todos lo datos están ingresados correctamente')
      }
    }
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogE
      },
      set: function (newValue) {
        this.$emit('update:dialogE', newValue)
      }
    },
    modoInput: {
      get: function () {
        return this.modo
      },
      set: function (newValue) {
        this.$emit('update:modo', newValue)
      }
    },
    nexamenInput: {
      get: function () {
        return this.nexamen
      },
      set: function (newValue) {
        this.$emit('update:nexamen', newValue)
      }
    },
    origenInput: {
      get: function () {
        return this.origen
      },
      set: function (newValue) {
        this.$emit('update:origen', newValue)
      }
    }
  },
  watch: {
    dialogE () {
      if (this.dialogE === false) {
        this.$refs.form.reset()
        this.reset()
      } else {
        if (this.modo === 'EDITAR') {
          this.cargarExamen()
        }
      }
    }
  }
}
</script>
