<template>
  <DialogVentana :dialog.sync="dialogInput" :loading.sync="loading" :width="500">
    <v-card style="box-shadow: none;">
      <v-card-text>
        <p class="display-1 primary--text font-weight-regular mt-2"> {{modoInput}} {{titulo}}</p>
        <v-form ref="form" v-model="valid">
          <v-text-field
            label="Nombre"
            v-model="nombre"
            style="margin-bottom: -5px;"
            :rules="[requerido]"
            counter
            maxlength="45"
            @change="existeTipoExamen"
            @keydown="checkKeyDownAlphaNumeric($event)"
          ></v-text-field>
          <v-autocomplete
            label="Procesado"
            :items="procesados"
            v-model="procesado"
            clearable
            style="margin-bottom: -5px;"
            no-data-text="Cargando datos..."
            :rules="[requerido]"
          ></v-autocomplete>
          <v-textarea
            v-model="desc"
            auto-grow
            filled
            label="Notas"
            rows="2"
            :rules="[requerido]"
            counter
            maxlength="45"
            class="mt-6"
          ></v-textarea>
          <v-row justify="center">
            <v-btn
              color="primary"
              @click="guardar"
              :disabled="!valid"
            >
            GUARDAR
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </DialogVentana>
</template>

<script>
import ApiCon from '@/logic/apicon.js'
import Globales from '@/mixins/globales.vue'
import DialogVentana from '@/components/dialogventana.vue'

export default {
  data: () => ({
    titulo: 'Tipo Exámen',
    nombre: '',
    procesado: '',
    desc: '',
    loading: false,
    valid: false,
    requerido: v => !!v || 'Este campo es obligatorio',
    idtipoexamen: 0,
    procesados: ['Interno', 'Externo'],
    backprocesado: 'white'
  }),
  mixins: [
    Globales
  ],
  components: {
    DialogVentana
  },
  props: ['modo', 'dialogTE', 'ntipoexamen', 'origen'],
  methods: {
    reset () {
      this.nombre = ''
      this.procesado = ''
      this.desc = ''
      this.idtipoexamen = 0
      this.backprocesado = 'white'
    },
    async existeTipoExamen () {
      if (this.nombre !== '') {
        const response = await ApiCon.get2('examentipos/existe', this.nombre, this.$session.get('usuario'))
        if (response.status === 200) {
          if (response.data === true) {
            this.nombre = ''
            alert('El tipo de examen ya se encuentra registrado.')
          }
        } else { alert('error existeTipoExamen') }
      }
    },
    async cargarTipoExamen () {
      this.loading = true
      const response = await ApiCon.get2('examentipos/unonombre', this.ntipoexamen, this.$session.get('usuario'))
      if (response.status === 200) {
        this.nombre = response.data.nombre
        this.procesado = response.data.procesado
        this.desc = response.data.desc
        this.idtipoexamen = response.data.id
      } else { alert('Error en cargarTipoExamen...') }
      this.loading = false
    },
    postMod () {
      if (this.origen === 'select') {
        this.cargarTabla('examentipos/listado', 'cambiarTiposExamen', 'cargarListaTiposExamen')
        this.ntipoexamenInput = this.nombre
      } else if (this.origen === 'tabla') {
        this.cargarTabla('examentipos/todo', 'cambiarTabla', 'cargarTablaTiposExamen')
      }
      this.reset()
      this.$refs.form.reset()
      this.dialogInput = false
    },
    async guardar () {
      if (this.valid === true) {
        this.loading = true
        var ins = {}
        if (this.modo === 'NUEVO') {
          ins = {
            nombre: this.nombre,
            procesado: this.procesado,
            desc: this.desc,
            usuario: this.$session.get('usuario')
          }
          const response = await ApiCon.post('examentipos/insert', ins)
          if (response.status === 200) {
            alert('El tipo examen ha sido registrado.')
            this.postMod()
          } else { alert('Ha ocurrido un error con el guardado de datos') }
        } else if (this.modo === 'EDITAR') {
          ins = {
            id: this.idtipoexamen,
            nombre: this.nombre,
            procesado: this.procesado,
            desc: this.desc
          }
          const response = await ApiCon.post('examentipos/update', ins)
          if (response.status === 200) {
            alert('El tipo examen ha sido actualizado.')
            this.postMod()
          } else { alert('Ha ocurrido un error con la edición de datos') }
        }
        this.loading = false
      } else {
        alert('Corrobore si todos lo datos están ingresados correctamente')
      }
    }
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogTE
      },
      set: function (newValue) {
        this.$emit('update:dialogTE', newValue)
      }
    },
    modoInput: {
      get: function () {
        return this.modo
      },
      set: function (newValue) {
        this.$emit('update:modo', newValue)
      }
    },
    ntipoexamenInput: {
      get: function () {
        return this.ntipoexamen
      },
      set: function (newValue) {
        this.$emit('update:ntipoexamen', newValue)
      }
    },
    origenInput: {
      get: function () {
        return this.origen
      },
      set: function (newValue) {
        this.$emit('update:origen', newValue)
      }
    }
  },
  watch: {
    dialogTE () {
      if (this.dialogTE === false) {
        this.$refs.form.reset()
        this.reset()
      } else {
        if (this.modo === 'EDITAR') {
          this.cargarTipoExamen()
        }
      }
    }
  }
}
</script>
